import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import VueCountryCode from "vue-country-code-select";

Vue.prototype.moment = moment;
Vue.use(VueAxios, axios);
Vue.use(VueCountryCode);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
