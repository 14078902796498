import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userAuth: false,
    createEmail: null,
    firstLoad: true,
  },
  getters: {
    getUserAuth: (state) => {
      return state.userAuth;
    },
    getCreateEmail: (state) => {
      return state.createEmail;
    },
    getFirstLoad: (state) => {
      return state.firstLoad;
    },
  },
  mutations: {
    userAuthSave(state, value) {
      state.userAuth = value;
    },
    userCreateEmail(state, value) {
      state.createEmail = value;
    },
    firstLoadSave(state, value) {
      state.firstLoad = value;
    },
  },
  actions: {},
  modules: {},
});
